var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "van-tab",
                { attrs: { name: "info", title: "仓储方信息" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "仓储方基本信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "仓储方全称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.currentInformationDetail.orgName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "统一社会信用代码",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.currentInformationDetail.orgNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "仓储方简称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.currentInformationDetail.orgShortName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "注册资本（万元）",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.currentInformationDetail.orgRegCap
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "成立日期",
                          value: _vm.utils.dateFormat(
                            _vm.currentInformationDetail.establishDate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "联系电话",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.currentInformationDetail.orgContactPhone
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "注册地址",
                          value: _vm.utils.newProvinceCityFormatter([
                            _vm.currentInformationDetail.orgRegProvince,
                            _vm.currentInformationDetail.orgRegCity,
                            _vm.currentInformationDetail.orgRegXq,
                            _vm.currentInformationDetail.orgRegDetail
                          ])
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "办公地址",
                          value: _vm.utils.newProvinceCityFormatter([
                            _vm.currentInformationDetail.orgWorkProvince,
                            _vm.currentInformationDetail.orgWorkCity,
                            _vm.currentInformationDetail.orgWorkXq,
                            _vm.currentInformationDetail.orgWorkDetail
                          ])
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "公司简介",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.currentInformationDetail.companyProfile
                          )
                        }
                      }),
                      _c(
                        "van-cell",
                        { attrs: { title: "企业营业执照" } },
                        [
                          _c("van-uploader", {
                            attrs: {
                              "show-upload": false,
                              deletable: false,
                              disabled: ""
                            },
                            on: { "click-preview": _vm.preview },
                            model: {
                              value: _vm.businessLicenseList,
                              callback: function($$v) {
                                _vm.businessLicenseList = $$v
                              },
                              expression: "businessLicenseList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "证件材料" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "法定代表人姓名",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.currentInformationDetail.businessName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "电子邮箱",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.currentInformationDetail.businessEmail
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "证件类型",
                          value: _vm.utils.statusFormat(
                            _vm.currentInformationDetail.businessSnType,
                            "typeCertificateType"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "证件号码",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.currentInformationDetail.businessSn
                          )
                        }
                      }),
                      _c(
                        "van-cell",
                        {
                          staticClass: "certificate-photo",
                          attrs: { title: "证件照片" }
                        },
                        [
                          _c("van-uploader", {
                            attrs: {
                              "show-upload": false,
                              deletable: false,
                              disabled: ""
                            },
                            on: { "click-preview": _vm.preview },
                            model: {
                              value: _vm.fileBusinessSnImgsList,
                              callback: function($$v) {
                                _vm.fileBusinessSnImgsList = $$v
                              },
                              expression: "fileBusinessSnImgsList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("van-tab", { attrs: { name: "quota", title: "资格审查" } }, [
                _c("div", { staticClass: "table goods-table" }, [
                  _c("table", { staticClass: "inner-goods-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("材料名称")]),
                        _c("th", [_vm._v("持有情况")]),
                        _c("th", [_vm._v("附件")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.qualificationList, function(item) {
                        return _c("tr", { key: item.materialId }, [
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(item.materialName)
                              )
                            )
                          ]),
                          _c("td", [
                            _c("span", { class: { danger: item.holdStatus } }, [
                              _vm._v(
                                _vm._s(item.holdStatus === 1 ? "有" : "无")
                              )
                            ])
                          ]),
                          _c(
                            "td",
                            [
                              _c("van-uploader", {
                                attrs: {
                                  "show-upload": false,
                                  deletable: false,
                                  disabled: ""
                                },
                                on: { "click-preview": _vm.preview },
                                model: {
                                  value: item.newFileDetailInfoVo,
                                  callback: function($$v) {
                                    _vm.$set(item, "newFileDetailInfoVo", $$v)
                                  },
                                  expression: "item.newFileDetailInfoVo"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ])
              ]),
              _c("van-tab", { attrs: { name: "quota1", title: "报价方案" } }, [
                _c("div", { staticClass: "table goods-table" }, [
                  _c("table", { staticClass: "inner-goods-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("费用类型")]),
                        _c("th", [_vm._v("收费标准")]),
                        _c("th", [_vm._v("备注")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.accountInfoVoList, function(item) {
                        return _c("tr", { key: item.materialId }, [
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(item.expenseType)
                              )
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  item.chargingStandard
                                )
                              )
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.utils.isEffectiveCommon(item.remark))
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                ])
              ]),
              _c(
                "van-tab",
                { attrs: { name: "file", title: "相关附件" } },
                [
                  _c("attachment-list", {
                    attrs: {
                      "module-name": "STORAGE",
                      "business-id": _vm.$route.query.id
                    }
                  })
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "audit", title: "审批意见" } },
                [
                  _c("workflow-audit", {
                    attrs: {
                      "page-key": "warehousing_co_add",
                      "business-key": _vm.$route.query.id,
                      "task-id": _vm.$route.query.taskId,
                      "proc-inst-id": _vm.$route.query.procInstId,
                      variable: {
                        businessId: _vm.$route.query.id,
                        motif: _vm.currentInformationDetail.orgShortName
                      }
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.utils.backReturn(_vm.$route.query.messageType)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }