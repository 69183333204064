<template>
  <div class="van-main has-fixed-btn">
    <div class="van-common-detail">
      <van-tabs v-model="activeName">
        <van-tab name="info" title="仓储方信息">
          <van-cell-group title="仓储方基本信息">
            <van-cell title="仓储方全称" :value="utils.isEffectiveCommon(currentInformationDetail.orgName)" />
            <van-cell title="统一社会信用代码" :value="utils.isEffectiveCommon(currentInformationDetail.orgNo)" />
            <van-cell title="仓储方简称" :value="utils.isEffectiveCommon(currentInformationDetail.orgShortName)" />
            <van-cell title="注册资本（万元）" :value="utils.isEffectiveCommon(currentInformationDetail.orgRegCap)" />
            <van-cell title="成立日期" :value="utils.dateFormat(currentInformationDetail.establishDate)" />
            <van-cell title="联系电话" :value="utils.isEffectiveCommon(currentInformationDetail.orgContactPhone)" />
            <van-cell title="注册地址" :value="utils.newProvinceCityFormatter([currentInformationDetail.orgRegProvince,currentInformationDetail.orgRegCity, currentInformationDetail.orgRegXq, currentInformationDetail.orgRegDetail])" />
            <van-cell title="办公地址" :value="utils.newProvinceCityFormatter([currentInformationDetail.orgWorkProvince,currentInformationDetail.orgWorkCity, currentInformationDetail.orgWorkXq, currentInformationDetail.orgWorkDetail])" />
            <van-cell title="公司简介" :value="utils.isEffectiveCommon(currentInformationDetail.companyProfile)" />
            <van-cell title="企业营业执照">
              <van-uploader v-model="businessLicenseList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
            </van-cell>
          </van-cell-group>
          <van-cell-group title="证件材料">
            <van-cell title="法定代表人姓名" :value="utils.isEffectiveCommon(currentInformationDetail.businessName)" />
            <van-cell title="电子邮箱" :value="utils.isEffectiveCommon(currentInformationDetail.businessEmail)" />
            <van-cell title="证件类型" :value="utils.statusFormat(currentInformationDetail.businessSnType, 'typeCertificateType')" />
            <van-cell title="证件号码" :value="utils.isEffectiveCommon(currentInformationDetail.businessSn)" />
            <van-cell title="证件照片" class="certificate-photo">
              <van-uploader v-model="fileBusinessSnImgsList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
            </van-cell>
          </van-cell-group>
        </van-tab>
        <van-tab name="quota" title="资格审查">
          <div class="table goods-table">
            <table class="inner-goods-table">
              <thead>
                <tr>
                  <th>材料名称</th>
                  <th>持有情况</th>
                  <th>附件</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in qualificationList" :key="item.materialId">
                  <td>{{ utils.isEffectiveCommon(item.materialName) }}</td>
                  <td>
                    <span :class="{ danger: item.holdStatus}">{{ item.holdStatus === 1 ? '有' : '无' }}</span>
                  </td>
                  <td>
                    <van-uploader v-model="item.newFileDetailInfoVo" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </van-tab>
        <van-tab name="quota1" title="报价方案">
          <div class="table goods-table">
            <table class="inner-goods-table">
              <thead>
                <tr>
                  <th>费用类型</th>
                  <th>收费标准</th>
                  <th>备注</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in accountInfoVoList" :key="item.materialId">
                  <td>{{ utils.isEffectiveCommon(item.expenseType) }}</td>
                  <td>{{ utils.isEffectiveCommon(item.chargingStandard) }}</td>
                  <td>{{ utils.isEffectiveCommon(item.remark) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </van-tab>
        <van-tab name="file" title="相关附件">
          <attachment-list module-name="STORAGE" :business-id="$route.query.id" />
        </van-tab>
        <van-tab name="audit" title="审批意见">
          <workflow-audit page-key="warehousing_co_add" :business-key="$route.query.id" :task-id="$route.query.taskId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:$route.query.id,motif: currentInformationDetail.orgShortName}" @confirmAudit="utils.backReturn($route.query.messageType)" />
        </van-tab>
      </van-tabs>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="utils.backReturn($route.query.messageType)" />
    </van-row>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Row, Button, Uploader, Checkbox, CheckboxGroup } from 'vant'
import WorkflowAudit from '@/components/workflow-audit'
import AttachmentList from '@/components/attachment-list'
// import fileList from '@/components/file-list'
export default {
  components: {
    AttachmentList,
    WorkflowAudit,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data () {
    return {
      activeName: 'info',
      currentInformationDetail: {},
      quotaDetail: {},
      qualificationList: [],
      businessLicenseList: [],
      fileBusinessSnImgsList: [],
      accountInfoVoList: []
    }
  },
  created () {
    this.getCompanyDetail()
  },
  methods: {
    // 处理文件数组
    getFileName (fileUrl) {
      const splitList = fileUrl ? fileUrl.split('/') : []
      return splitList[splitList.length - 1] || ''
    },
    getCompanyDetail () {
      this.utils.loading()
      this.api.task.companyAccess.currentInformationDetail(this.$route.query.id).then(result => {
        this.currentInformationDetail = result.data.value
        this.getQualificationList()
        this.getSchemeList()
        if (this.currentInformationDetail.orgLicenseImgs) {
          this.businessLicenseList = [{
            fileUrl: this.currentInformationDetail.orgLicenseImgs,
            url: this.currentInformationDetail.orgLicenseImgs,
            src: this.currentInformationDetail.orgLicenseImgs,
            fileName: this.getFileName(this.currentInformationDetail.orgLicenseImgs),
            name: this.getFileName(this.currentInformationDetail.orgLicenseImgs),
            id: 0
          }]
        } else {
          this.businessLicenseList = []
        }
        if (this.currentInformationDetail.businessSnPreImgs) {
          this.fileBusinessSnImgsList.push({
            fileUrl: this.currentInformationDetail.businessSnPreImgs,
            url: this.currentInformationDetail.businessSnPreImgs,
            fileName: this.getFileName(this.currentInformationDetail.businessSnPreImgs),
            name: this.getFileName(this.currentInformationDetail.businessSnPreImgs),
            id: 0
          })
        }
        if (this.currentInformationDetail.businessSnBackImgs) {
          this.fileBusinessSnImgsList.push({
            fileUrl: this.currentInformationDetail.businessSnBackImgs,
            url: this.currentInformationDetail.businessSnBackImgs,
            fileName: this.getFileName(this.currentInformationDetail.businessSnBackImgs),
            name: this.getFileName(this.currentInformationDetail.businessSnBackImgs),
            id: 0
          })
        }
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    getQualificationList () {
      this.utils.loading()
      this.api.warehouseManagement.qualificationList(this.currentInformationDetail.enterpriseId, this.$route.query.type === 6 ? 'modify' : 'edit').then(result => {
        result.data.value.forEach(item => {
          if (item.fileDetailInfoVo) {
            item.fileDetailInfoVo.url = item.fileDetailInfoVo.fileUrl
          }
          item.newFileDetailInfoVo = [item.fileDetailInfoVo]
        })
        this.qualificationList = result.data.value
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    getSchemeList () {
      this.utils.loading()
      this.api.warehouseManagement.schemeList(this.currentInformationDetail.enterpriseId, this.$route.query.type === 6 ? 'modify' : 'edit').then(res => {
        this.accountInfoVoList = res.data.value || []
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    getQuotaDetail () {
      this.api.task.companyAccess.quotaInformationDetail(this.$route.query.id).then(result => {
        const data = result.data.value
        if (data && data.productType) {
          data.productType = data.productType.split(',')
        } else {
          data.productType = []
        }
        this.quotaDetail = data
      })
    },
    preview (file, detail) {
      if (file.fileType) {
        const fileTypeArr = file.fileType.split('/')
        if (fileTypeArr[0] !== 'image') {
          window.open(file.url)
        }
      }
    }
  }
}
</script>

<style scoped>
  .certificate-photo >>> .van-uploader__wrapper {
    display: inline-flex !important;
    flex-wrap: inherit !important;
  }
  .van-common-detail >>> .van-tabs .van-tabs__wrap .van-tabs__nav:first-child .van-tab{
    padding: 0 !important;
  }
</style>
